<template>
  <div>
    <startBuyDialog ref="startBuyDialog" @callback="doStartBuy" />
    <building ref="building" />
    <div
      class="mainV2_card_box"
      v-loading="loading"
      :class="'skin_theme_' + skin_theme"
    >
      <div class="mainV2_card" v-for="item in list" :key="item.img">
        <div class="script_bg_img"></div>
        <div class="card_img_box">
          <img class="card_img" @load="imgOnload($event)" :src="item.img" />
        </div>
        <div class="card_bottom">
          <div class="text_box">
            <p class="top_text">{{ item.name }}</p>
            <p class="bottom_text">{{ item.tag[0] }}</p>
          </div>
          <div class="text_btn">
            <el-button class="start_btn" @click="startBuy(item.id)">
              {{ $lang("editWeb_free_construction") }}
            </el-button>
            <el-button class="see_btn" @click="goLook(item.id)">
              {{ $lang("editWeb_preview") }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get_template_list } from "@/api/tWeb.js";
import { do_start_buy } from "@/api/startBuy.js";

import startBuyDialog from "@/components/search/startBuyDialog.vue";
import building from "@/components/search/building.vue";

export default {
  components: {
    startBuyDialog,
    building,
  },
  data() {
    return {
      list: [],
      loading: false,
      isBlank: false,
    };
  },
  methods: {
    // 渲染card
    get_list() {
      this.loading = true;
      let params = {};
      get_template_list(params)
        .then((res) => {
          this.list = res.data.list;
          //   let list = [];
          //   for (let i = 0; i < 10; i++) {
          //     list.push(res.data.list[0]);
          //   }
          //   this.list = list;
        })
        .catch((err) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    goLook(id) {
      this.$router.push("/index/search/details?id=" + id);
    },
    startBuy(id) {
      this.$refs.startBuyDialog.start(id);
    },
    doStartBuy(data) {
      let id = data.template_id;
      let web_name = data.web_name;
      //   this.loading = true;
      this.$refs.building.dialogVisible = true;
      do_start_buy({ template_id: id, web_name })
        .then((res) => {
          let web_id = res.data.web_id;
          setTimeout(() => {
            this.$notify({
              type: "success",
              message: "免费持有7日成功",
            });
            this.$router.push(
              "/editWeb?web_id=" + web_id + "&goBack=userCenter"
            );
          }, 5000);
        })
        .catch((err) => {
          if (err.code == 201) {
            this.$refs.building.dialogVisible = false;
          }
        })
        .finally(() => {
          //   this.loading = false;
          setTimeout(() => {
            this.$refs.building.dialogVisible = false;
          }, 5000);
        });
      console.log("startBuy template_id", id);
    },

    imgOnload(event) {
      let img = event.target;
      img.addEventListener("mouseover", () => {
        img.style.top = -(img.height - 250) + "px";
      });

      img.addEventListener("mouseout", () => {
        img.style.top = 0;
      });
    },
  },
  mounted() {
    this.get_list();
    // 初始化
    let theme = JSON.parse(window.localStorage.getItem("theme"));
    if (theme === "blankTheme") {
      this.isBlank = true;
    } else {
      this.isBlank = false;
    }
    // 接收更改主题变化
    this.$bus.$on("curTheme", (data) => {
      if (data) {
        this.isBlank = true;
      } else {
        this.isBlank = false;
      }
    });
  },
  computed: {
    skin_theme() {
      return this.$store.getters.skin_theme;
    },
  },
};
</script>

<style lang="less" scoped>
// 特效
.mainV2_card {
  transition: all 0.5s;
}
.mainV2_card:hover {
  transform: translateY(-10px);
  // box-shadow: 0 0 19px #a49dff;
}
.card_img {
  transition: all 8s;
}

.mainV2_card:hover .text_btn {
  display: flex !important;
}
.mainV2_card:hover .text_box {
  display: none !important;
}
.mainV2_card:hover .card_bottom {
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.11);
  top: 2px;
}

.mainV2_card_box {
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .mainV2_card {
    width: 356px;
    border-radius: 14px;
    margin-bottom: 50px;
    margin-right: 70px;
    position: relative;
    cursor: pointer;
    .script_bg_img {
      width: 356px;
      height: 231px;
      background: url("http://project-all.oss-cn-beijing.aliyuncs.com/jianzhan/20211204/191750_611490.png")
        no-repeat -893px -388px;
      padding: 6px;
      position: absolute;
      left: -5px;
      top: -20px;
      box-sizing: content-box;
    }
    .card_img_box {
      // border-radius: 14px;
      width: 356px;
      height: 216px;
      overflow: hidden;
      position: relative;
      .card_img {
        width: 100%;
        height: auto;
        position: absolute;
        top: 0;
      }
    }
    .card_bottom {
      width: 356px;
      height: 58px;
      background-color: #fff;
      position: relative;
      top: 0;
      .text_btn {
        height: 100%;
        display: none;
        justify-content: center;
        align-items: center;
        .el-button {
          border-radius: 20px;
          margin: 0 16px;
          padding: 10px 30px;
        }
        .el-button.start_btn {
          border: 1px solid tomato;
          background-color: tomato;
          color: #fff;
          &:hover {
            border: 1px solid #fd816d;
            background-color: #fd816d;
            color: #fff;
          }
        }

        .el-button.see_btn {
          border: 1px solid #409eff;
          background-color: #409eff;
          color: #fff;
          &:hover {
            border: 1px solid #5badff;
            background-color: #5badff;
            color: #fff;
          }
        }
      }
      .text_box {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .top_text {
          height: 17px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          line-height: 17px;
        }
        .bottom_text {
          height: 14px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 14px;
          margin-top: 4px;
        }
      }
    }
  }
  .mainV2_card:nth-child(3n + 1) {
    margin-right: 0;
  }
}

.mainV2_card_box.skin_theme_one {
  .card_bottom {
    background-color: #fff;
  }
  .top_text,
  .bottom_text {
    color: #333;
  }
}

.mainV2_card_box.skin_theme_two {
  .card_bottom {
    background-color: #1d1c1e;
  }
  .top_text,
  .bottom_text {
    color: #fff;
  }
}
</style>