<template>
  <div class="index_page_v2">
    <!-- 头部 -->
    <div class="max_header">
      <headerV3></headerV3>
      <el-button class="change_theme" @click="changeColor">
        {{ $lang("change_theme") }}
      </el-button>
    </div>

    <div style="height: 80px"></div>

    <!-- 身体 -->
    <div class="main_box">
      <div class="main">
        <mainV2></mainV2>
      </div>
    </div>
  </div>
</template>

<script>
import headerV3 from "@/views/index/search/V2c/c/headerV3.vue";
import mainV2 from "@/views/index/search/V2c/mainV2.vue";
import { Changetheme, initialTheme } from "@/utils/indexTheme";

export default {
  components: {
    headerV3,
    mainV2,
  },
  data() {
    return {
      isChangeTheme: false,
    };
  },
  mounted() {
    // 初始化
    let theme = JSON.parse(window.localStorage.getItem("theme"));
    if (!theme) {
      initialTheme();
      return;
    } else {
      if (theme === "blankTheme") {
        this.isChangeTheme = true;
        Changetheme();
        return;
      }
      if (theme === "whiteTheme") {
        initialTheme();
        return;
      }
    }
  },
  methods: {
    // 换肤按钮
    changeColor() {
      this.isChangeTheme = !this.isChangeTheme;

      if (this.isChangeTheme) {
        // 防止后续传入的不是字符串
        window.localStorage.setItem("theme", JSON.stringify("blankTheme"));
        // 暗黑主题
        Changetheme();
      } else {
        window.localStorage.setItem("theme", JSON.stringify("whiteTheme"));
        // 光明主题
        initialTheme();
      }
      this.$bus.$emit("curTheme", this.isChangeTheme);
    },
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
  },
};
</script>

<style lang="less" scoped>
body {
  --xzd_color1: #f3f5ff !important;
  --xzd_color2: #ebefff !important;
  --xzd_color3: #333 !important;
  --xzd_color4: #615bff !important;
  --xzd_color5: #aeabff !important;
  --xzd_color6: #4943ff !important;
}
.index_page_v2 {
  background: #fff;
  width: 100vw;
  height: 100vh;

  .max_header {
    width: 100%;
    background-color: #fff;
    color: #333;
    position: fixed;
    top: 0;
    z-index: 100;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.082);
    .change_theme {
      position: absolute;
      top: 26px;
      left: 20%;
      background-color: #fff;
      border-color: var(--xzd_color4);
      color: var(--xzd_color4);
      height: 22px;
      font-size: 12px;
      padding: 3px;
    }
    .change_theme:hover {
      color: #409eff;
      border-color: #409eff;
    }
  }

  .main_box {
    background-color: var(--xzd_color1);
  }
  // 身体
  .main {
    display: flex;
    width: 1250px;
    margin: 0 auto;
    position: relative;
    z-index: 1;

    // 左侧
    .left {
      width: 212px;
      margin-right: 25px;
    }

    // 右侧
    .right {
      width: 1010px;
      background: var(--xzd_color2);
      border-radius: 20px;
    }
  }
}
</style>