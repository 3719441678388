<template>
  <div class="header-box" :class="'skin_theme_' + skin_theme">
    <div class="header-left-box">
      <!-- <img
        class="logo"
        @click="$router.push('/')"
        src="@/assets/imgs/test/logo_logo.png"
        alt="logo"
        v-if="skin_theme === 'one'"
      />
      <img
        class="logo"
        @click="$router.push('/')"
        src="@/assets/imgs/home-logo.png"
        alt="logo"
        v-else-if="skin_theme === 'two'"
      /> -->
      ALI-WEB
    </div>

    <div class="header-center-box">{{ $lang("hope") }}</div>

    <div class="header-right-box">
      <div>
        <el-button class="change_theme_btn" @click="changeColor">
          {{ $lang("change_theme") }}
        </el-button>
      </div>

      <chooseLanguage />
      <userInfo />
    </div>
  </div>
</template>

<script>
import userInfo from "@/components/common/header/c/userInfo.vue";
import chooseLanguage from "@/components/common/header/c/chooseLanguage.vue";

export default {
  data() {
    return {};
  },
  components: {
    userInfo,
    chooseLanguage,
  },
  computed: {
    skin_theme() {
      return this.$store.getters.skin_theme;
    },
  },

  methods: {
    changeColor() {
      if (this.skin_theme == "one") {
        this.$store.commit("set_skin_theme", "two");
      } else if (this.skin_theme == "two") {
        this.$store.commit("set_skin_theme", "one");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.header-box {
  cursor: pointer;
  width: 100%;
  height: 72px;
  margin: 0px auto;
  display: flex;
  align-items: center;
  box-sizing: border-box;

  .header-left-box {
    width: 300px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .logo {
      width: 89px;
      height: 30px;
    }
  }
  .header-center-box {
    flex: 1;
    text-align: center;
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 700;
  }

  .header-right-box {
    width: 300px;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    cursor: pointer;

    .avatar {
      width: 29px;
      height: 29px;
      border-radius: 50%;
      margin-left: 32px;
    }
  }
}

.header-box.skin_theme_one {
  background-color: #fff;
}
.header-box.skin_theme_two {
  background-color: #151417;
}

.change_theme_btn {
  font-size: 12px;
  padding: 3px;
  margin-right: 20px;
}
.change_theme_btn:hover {
  color: #409eff;
  border-color: #409eff;
}
</style>