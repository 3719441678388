<template>
  <div>
    <el-dialog
      title="建站"
      :visible.sync="dialogVisible"
      width="400px"
      :modal-append-to-body="false"
    >
      正在快马加鞭地建站中 <i class="el-icon-loading"></i>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
    };
  },
};
</script>

<style lang="less" scoped>
</style>