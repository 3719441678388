<template>
  <div>
    <el-dialog
      :title="$lang('startBuyDialog_title')"
      :visible.sync="dialogVisible"
      width="400px"
      :modal-append-to-body="false"
    >
      <el-input
        v-model="web_name"
        :placeholder="$lang('startBuyDialog_subtitle')"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">
          {{ $lang("startBuyDialog_btn1") }}
        </el-button>
        <el-button type="primary" @click="onSubmit">
          {{ $lang("startBuyDialog_btn2") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      template_id: 0,
      web_name: "",
    };
  },
  methods: {
    start(template_id) {
      this.dialogVisible = true;
      this.template_id = template_id;
      this.web_name = "";
    },

    onSubmit() {
      this.dialogVisible = false;

      let template_id = this.template_id;
      let web_name = this.web_name;
      if (!web_name) {
        this.$notify({
          type: "error",
          message: "请为您的网站起一个响亮的名称",
        });
        return false;
      }

      let data = {
        template_id,
        web_name,
      };
      this.$emit("callback", data);
    },
  },
};
</script>

<style lang="less" scoped>
</style>