/*
免费持有7天 相关接口
*/

import request from '@/utils/request'

/*
免费持有7天
params:{user_id,user_token,template_id}
*/
export const do_start_buy = (params) => {
    let url = "/index.php/api/v1_0_0.startbuy/do_start_buy";
    return request.post(url, params)
}



