<template>
  <div class="mainV2" :class="'skin_theme_' + skin_theme">
    <div class="card_box">
      <mainV2Card></mainV2Card>
    </div>
  </div>
</template>

<script>
import mainV2Card from "./mainV2Card.vue";

export default {
  components: {
    mainV2Card,
  },
  data() {
    return {
      titleClose: true,
    };
  },
  methods: {
    closeTitle() {
      //   this.titleClose = false;
    },
    goToIndex() {
      this.$router.push("/");
    },
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
    skin_theme() {
      return this.$store.getters.skin_theme;
    },
  },
};
</script>

<style lang="less" scoped>
.mainV2 {
  width: 100%;
  height: 100%;
  padding: 23px 26px;
  overflow-y: auto;

  // card
  .card_box {
    margin-top: 30px;
  }
}
</style>